@import "vars.scss";
.loginScreen {
	background-color: transparent;
	label {
		color: $heading;
		padding-bottom: 12px;
	}
	input {
		margin-top: 8px;
		padding: 12px 12px;
	}
	button, .loginBtn {
		outline: 0;
		box-shadow: none;
		border: 0;
		padding: 14px 12px;
		width: 100%;
		display: block;
		background-color: $blue;
		color: #000;
		margin-bottom: 32px;
		cursor: pointer;
		text-align: center;
		&[type="submit"] {
			color: #fff;
			background-color: $cyan;
		}
	}
	h1 {
		padding-top: 12px;
		@media (min-width: 769px) {
			padding-top: 48px;
		}
	}
	h2 {
		margin-top: 32px;
	}
}

.loginBanner {
	@media (max-width: 768px) {
		max-height: 330px;
		overflow: hidden;
	}
	>div > img {
		@media (max-width: 768px) {
			top: -30% !important;
		}
	}
}